import {useMemo} from 'react';

import {Text} from 'rebass';
import {useHistory} from 'react-router-dom';

import {useContractorProjectsV2} from '@renofi/api';
import {
  darkBlue,
  fadeBlue,
  fadeGreen,
  formatIsoDate,
  toCurrency,
} from '@renofi/utils';
import {AddNoteIcon, Badge, SkeletonText} from '@renofi/components';

import getStatusLabel from '../../getStatusLabel';

import {Tr, Td, DateSuffix, UploadLink} from './styled';
import {ADDRESS_CELL_WIDTH} from './constants';

const EMPTY = '-';

const TableRow = ({entity, loading}) => {
  const history = useHistory();
  const status =
    entity?.entityProjectStatus ||
    entity?.entityLeadStatus ||
    entity?.entityDesignStatus;
  const isProject = entity?.normalizedEntityType === 'project';
  const {projects} = useContractorProjectsV2({skip: !isProject});

  const actionText = useMemo(() => {
    if (!isProject) {
      return EMPTY;
    }

    const project = projects.find(({id}) => id === entity?.entityId);
    const projectDocs = project?.documents || [];
    const hasUploaded = projectDocs.some(({source}) => source === 'contractor');

    return hasUploaded ? 'View documents' : 'Upload renovation documents';
  }, [isProject, JSON.stringify({entity, projects})]);

  return (
    <Tr
      role={`client_row_${entity?.id}`}
      onClick={() => {
        if (!loading) history.push(`/financing/client/${entity?.id}`);
      }}>
      <Td>
        <SkeletonText skeletonWidth={190} animate={loading}>
          {entity?.entityFullName}
        </SkeletonText>
      </Td>
      <Td>
        <SkeletonText skeletonWidth={110} animate={loading}>
          {formatIsoDate(entity?.createdAt)}
        </SkeletonText>
      </Td>
      <Td
        style={{
          maxWidth: ADDRESS_CELL_WIDTH,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}>
        {entity?.entityPropertyAddress}
      </Td>
      <Td>{toCurrency(entity?.entityRenovationCost, {prefix: '$'})}</Td>
      <Td>
        <SkeletonText skeletonWidth={140} animate={loading}>
          {status ? (
            <>
              {getStatusLabel(status)}
              <DateSuffix>{formatIsoDate(entity?.updatedAt)}</DateSuffix>
            </>
          ) : (
            EMPTY
          )}
        </SkeletonText>
      </Td>
      <Td>
        {!loading ? (
          <>
            {(entity?.entityProjectStatus || entity?.entityLeadStatus) && (
              <Badge
                color={fadeGreen}
                size={12}
                fontWeight="normal"
                textTransform="normal"
                textColor={darkBlue}>
                Financing
              </Badge>
            )}
            {entity?.entityDesignStatus ? (
              <Badge
                ml={2}
                color={fadeBlue}
                size={12}
                fontWeight="normal"
                textTransform="normal"
                textColor={darkBlue}>
                Design
              </Badge>
            ) : null}
          </>
        ) : null}
      </Td>
      <Td>
        {isProject ? (
          <UploadLink to={`/financing/${entity.entityId}`}>
            <AddNoteIcon color={darkBlue} hoverColor={darkBlue} />
            <Text>{actionText}</Text>
          </UploadLink>
        ) : (
          EMPTY
        )}
      </Td>
    </Tr>
  );
};

export default TableRow;
