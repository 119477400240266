import {noop} from 'lodash';

import {
  chilipiper,
  initDataDog,
  initSegment,
  initSentry,
  isTestSession,
} from '@renofi/analytics';

export const initAnalytics = () => {
  initDataDog();

  if (isTestSession()) {
    return;
  }

  initSegment();
  initSentry();
  initDataDog();
};

export const launchChilipiper = ({
  onClose = noop,
  onSuccess = noop,
  router = 'partnerships-contractors',
  user = {},
} = {}) => {
  const {email, firstName, lastName, phoneNumber: phone} = user || {};

  chilipiper.initSchedule({
    analyticsPrefix: 'Contractors',
    lead: {email, firstName, lastName, phone},
    onClose,
    onSuccess,
    router,
  });
};
