/* eslint-disable max-lines */
import React, {useState, useEffect, useCallback} from 'react';

import {useReactiveVar} from '@apollo/client';
import PropTypes from 'prop-types';

import {useLocalStorage} from '@renofi/utils';
import {
  useQuestionnaireSignedUrl,
  useUpdateContractorLicense,
  useUpdateContractorInsurance,
} from '@renofi/api/src/contractor';
import {Toggle, useNotifications} from '@renofi/components';

import {eligibilityVar, companyVar, borrowersVar} from '../../api/cache';
import {Heading} from '../components';
import DeleteFileModal from '../Eligibility/DeleteFileModal/DeleteFileModal';
import License from '../Eligibility/License';
import GeneralLiability from '../Eligibility/GeneralLiability';
import WorkersCompInsurance from '../Eligibility/WorkersCompInsurance';
import useFormData from '../Eligibility/hooks/useFormData';
import useAcceptFiles from '../Eligibility/hooks/useAcceptFiles';
import useRemoveFile from '../Eligibility/hooks/useRemoveFile';
import useUploadingFileState from '../Eligibility/hooks/useUploadingFileState';
import Footer from '../Footer';
import {getRejectedDocumentsTitle} from '../utils';

const RejectedDocuments = ({questionnaireId}) => {
  // Apollo
  const eligibility = useReactiveVar(eligibilityVar);
  const company = useReactiveVar(companyVar);
  const borrowerNames = useReactiveVar(borrowersVar);
  const {updateContractorLicense} = useUpdateContractorLicense();
  const {updateContractorInsurance} = useUpdateContractorInsurance();
  const getSignedUrl = useQuestionnaireSignedUrl();
  const addNotification = useNotifications();

  // App
  const [showDeleteFileModal, setShowDeleteFileModal] = useState(false);
  const [removeFileKey, setRemoveFileKey] = useState('');
  const [token, setToken] = useLocalStorage('renofi:token');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useFormData(eligibility);
  const [uploadingFileState, setUploadingFileState] = useUploadingFileState();
  const acceptFiles = useAcceptFiles({
    page: 'rejected_documents',
    setLoading,
    addNotification,
    questionnaireId,
    token,
    setFormData,
    setUploadingFileState,
    state: eligibility?.propertyState,
  });
  const removeFile = useRemoveFile({
    questionnaireId,
    token,
    setShowDeleteFileModal,
    setLoading,
    setFormData,
    setUploadingFileState,
    addNotification,
  });

  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    const title = getRejectedDocumentsTitle(formData, company?.businessName);
    setPageTitle(title);
  }, []);

  function onChange(key, value) {
    setFormData({...formData, [key]: value});
  }

  function onReasonChange(collectionKey, reasonKey, value) {
    let reasonsArr = [];
    if (value) {
      reasonsArr = [...formData[collectionKey], reasonKey];
    } else {
      reasonsArr = formData[collectionKey].filter(
        (reason) => reason !== reasonKey,
      );
    }

    setFormData({
      ...formData,
      [collectionKey]: reasonsArr,
    });
  }

  async function onSubmitValue() {
    setLoading(true);

    try {
      if (formData?.contractorLicense) {
        await updateContractorLicense({
          variables: {
            expiryDate: formData?.contractorLicense?.expiryDate,
            hasLicense: formData?.hasLicense,
            noLicenseReasons: formData?.noLicenseReasons,
            noLicenseReasonsOther: formData?.noLicenseReasonsOther,
            number: formData?.licenseNumber,
            state: eligibility?.propertyState,
            questionnaireId,
            token,
          },
        });
      }

      if (formData?.contractorGeneralLiabilityInsurance?.expiryDate) {
        await updateContractorInsurance({
          variables: {
            documentType: 'contractor_general_liability_insurance',
            expiryDate:
              formData?.contractorGeneralLiabilityInsurance?.expiryDate,
            questionnaireId,
            token,
          },
        });
      }

      if (formData?.contractorWorkersCompInsurance) {
        await updateContractorInsurance({
          variables: {
            documentType: 'contractor_workers_comp_insurance',
            expiryDate: formData?.contractorWorkersCompInsurance?.expiryDate,

            hasWorkersCompInsurance: formData?.hasWorkersCompInsurance,
            noInsuranceReasons: formData?.noInsuranceReasons,
            noInsuranceReasonsOther: formData?.noInsuranceReasonsOther,
            workersCompIncludedInGlid: formData?.workersCompIncludedInGlid,
            questionnaireId,
            token,
          },
        });
      }

      addNotification({
        type: 'success',
        message: 'Successfully saved',
      });
    } catch (error) {
      addNotification({
        type: 'error',
        message: error?.message || 'Failed to save',
      });
    }
    setToken(token);
    setLoading(false);
  }

  const onAcceptFiles = useCallback(
    (files, key) => {
      acceptFiles({formData, uploadingFileState}, files, key);
    },
    [formData, uploadingFileState],
  );

  const onConfirmRemoval = useCallback(
    (key) => {
      removeFile(key, {
        uploadingFileState,
        formData,
      });
    },
    [formData, uploadingFileState],
  );

  function onFileRemove(key) {
    setShowDeleteFileModal(true);
    setRemoveFileKey(key);
  }

  async function onFileView(fileName) {
    const result = await getSignedUrl({
      questionnaireId,
      token,
      objectName: fileName,
      accessTo: 'rejected_documents',
    });

    return result?.data?.questionnaireSgnedUrl?.url || '';
  }

  const isSectionVisible = (key) => {
    return Boolean(
      formData[key]?.rejectionReason || formData[key]?.isSuccessfullyUploaded,
    );
  };

  const isLicenseVisible = isSectionVisible('contractorLicense');
  const isInsuranceVisible = isSectionVisible(
    'contractorGeneralLiabilityInsurance',
  );
  const isWorkersCompVisible = isSectionVisible(
    'contractorWorkersCompInsurance',
  );

  const isFooterVisible =
    isLicenseVisible || isInsuranceVisible || isWorkersCompVisible;

  const isSaveBtnDisabled = () => {
    return (
      loading ||
      formData.contractorLicense?.rejectionReason ||
      formData.contractorGeneralLiabilityInsurance?.rejectionReason ||
      formData.contractorWorkersCompInsurance?.rejectionReason
    );
  };

  return (
    <>
      <>
        <Heading
          m={['0 0 24px 0', '0 -95px 30px -95px']}
          p={['0 0 24px 0', '12px 0 20px 0']}
          css={{borderBottom: '1px solid #d8d8d8'}}
          center>
          {pageTitle}
        </Heading>

        <Toggle show={isLicenseVisible}>
          <License
            formData={formData}
            onChange={onChange}
            onReasonChange={onReasonChange}
            onFileRemove={onFileRemove}
            onFileView={onFileView}
            onAcceptFiles={onAcceptFiles}
            state={eligibility?.propertyState}
            homeownerName={borrowerNames}
            isUploadInProgress={uploadingFileState?.contractorLicense}
            showTitle
          />
        </Toggle>

        <Toggle show={isInsuranceVisible}>
          <GeneralLiability
            contractorGeneralLiabilityInsurance={
              formData.contractorGeneralLiabilityInsurance
            }
            onFileRemove={onFileRemove}
            onFileView={onFileView}
            onAcceptFiles={onAcceptFiles}
            homeownerName={borrowerNames}
            isUploadInProgress={
              uploadingFileState.contractorGeneralLiabilityInsurance
            }
          />
        </Toggle>

        <Toggle show={isWorkersCompVisible}>
          <WorkersCompInsurance
            formData={formData}
            onChange={onChange}
            onReasonChange={onReasonChange}
            onFileRemove={onFileRemove}
            onFileView={onFileView}
            onAcceptFiles={onAcceptFiles}
            showWorkersCompInsurance
            isUploadInProgress={
              uploadingFileState.contractorWorkersCompInsurance
            }
          />
        </Toggle>
      </>

      <DeleteFileModal
        id="modal"
        show={showDeleteFileModal}
        title="Delete file"
        onClose={() => setShowDeleteFileModal(false)}
        onConfirm={onConfirmRemoval}
        removeFileKey={removeFileKey}
      />

      <Toggle show={isFooterVisible}>
        <Footer
          showNext
          showBack={false}
          nextText="Save Updates"
          justifyContent="center"
          onNext={onSubmitValue}
          loading={loading}
          disabled={isSaveBtnDisabled()}
        />
      </Toggle>
    </>
  );
};

RejectedDocuments.propTypes = {
  questionnaireId: PropTypes.string,
};

export default RejectedDocuments;
