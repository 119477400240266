import {Flex, Text} from 'rebass';
import {useHistory} from 'react-router-dom';

import {Button} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';
import {useCurrentUser} from '@renofi/api';

import {Layout} from '../components';

import Card from './components/QuickStartOption';
import dollarSrc from './img/dollar.svg';
import loanOfficerSrc from './img/loan-officer.svg';
import downloadSrc from './img/download.svg';

const QuickStart = () => {
  const history = useHistory();
  const {isMobile} = useScreenSize();
  const {user} = useCurrentUser();

  const {firstName} = user || {};
  const title = firstName ? `Welcome ${firstName}` : 'Welcome!';
  const suffix = firstName
    ? ', what would you like to do today?'
    : 'What would you like to do today?';
  return (
    <Layout.AuthenticatedPage
      title={
        <Text
          role="page_title"
          fontSize={[32, 40]}
          textAlign={['center', 'left']}
          lineHeight="normal">
          <strong>{title}</strong>
          {!isMobile && <>{suffix}</>}
        </Text>
      }>
      <Flex flexWrap="wrap" css={{gap: 24}}>
        <Card
          iconSrc={dollarSrc}
          headline={
            <>
              Send your client a{' '}
              <strong>monthly payment and financing options</strong> for their
              project
            </>
          }
          description="Help your client consider their project as a monthly expense rather than a lump sum payment.">
          <Button xSmall onClick={() => history.push('/financing-estimates')}>
            Generate Monthly Payment
          </Button>
        </Card>
        <Card
          iconSrc={loanOfficerSrc}
          headline={
            <>
              Connect a client with <strong>a RenoFi Loan Officer</strong>
            </>
          }
          description="We'll help them understand the loan options available to them.">
          <Button xSmall onClick={() => history.push('/financing')}>
            Connect a client
          </Button>
        </Card>
        <Card
          iconSrc={downloadSrc}
          headline={
            <>
              Download your <strong>co-branded marketing</strong> assets
            </>
          }
          description="Upload your logo and we’ll automatically generate a co-branded page and marketing assets for you for free.">
          <Button xSmall secondary onClick={() => history.push('/marketing')}>
            Download
          </Button>
        </Card>
      </Flex>
    </Layout.AuthenticatedPage>
  );
};

export default QuickStart;
