import * as uuid from 'uuid';

import {getChecksum, readFile} from '@renofi/utils';

import useToggledQuery from '../../useToggledQuery';
import {GET_UPLOAD_METADATA} from '../../queries';

export default function useUploadMetadata({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery(GET_UPLOAD_METADATA, {
    lazy,
    fetchPolicy,
    ...options,
  });

  const {data, error, loading, fetch} = response;
  const uploadMetadata = data?.uploadMetadata;

  const fetchFilesMetadata = async ({
    basePath = 'documents',
    files = [],
  } = {}) => {
    const response = await Promise.all(
      files.map(async (file) => {
        const {name: fileName, type: contentType} = file;
        const rsp = await fetch({
          variables: {
            fileName,
            contentType,
            basePath,
            recordId: uuid.v4(),
          },
        });

        const data = rsp?.data?.uploadMetadata || {};
        const blob = await readFile(file);
        const checksum = await getChecksum(blob);

        return {...data, checksum, file};
      }),
    );
    return response;
  };

  return {error, fetch, fetchFilesMetadata, uploadMetadata, loading};
}
