import {useEffect, useState, useContext} from 'react';

import {useHistory} from 'react-router-dom';
import {Flex, Box, Text, Link} from 'rebass';

import {useValidateEmail, useRequestUserRegistration} from '@renofi/api';
import {sendEvent} from '@renofi/analytics';
import {useSessionStorage} from '@renofi/utils';
import {
  Label,
  Button,
  PrivacyCheckbox,
  CheckboxLabel,
  useNotifications,
} from '@renofi/components';

import AppContext from '../context';
import {AUTH_EMAIL_SESSION_KEY} from '../constants';
import {EmailField, AuthContainer} from '../components';

const ERROR_MSG = 'There was a problem registering this email';

const accountCategory = 'contractor';

const agreements = {
  contractorTermsOfService: true,
  electronicSignature: true,
};

const Signup = () => {
  const {isLoggedIn} = useContext(AppContext);
  const history = useHistory();
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [_, setSessionEmail] = useSessionStorage(AUTH_EMAIL_SESSION_KEY);
  const addNotification = useNotifications();
  const {loading, validateEmail} = useValidateEmail();

  const {loading: isRegistering, requestUserRegistration} =
    useRequestUserRegistration();

  const acceptedAgreements = termsAccepted ? agreements : null;

  const isDisabled = !(email && termsAccepted) || isRegistering || invalidEmail;

  useEffect(() => {
    if (isLoggedIn) {
      return history.push('/');
    }
  }, [isLoggedIn]);

  const onSubmit = async () => {
    const {data: validationData} = await validateEmail({variables: {email}});

    if (!validationData?.validateEmail?.valid) {
      setInvalidEmail(true);
      return;
    }

    try {
      const rsp = await requestUserRegistration({
        variables: {accountCategory, email, acceptedAgreements},
      });
      const success = Boolean(rsp?.data?.requestUserRegistration?.success);

      const eventName = success
        ? 'Contractors/User-Registation-Success'
        : 'Contractors/User-Registation-Fail';

      sendEvent(eventName, {email});

      if (success) {
        setSessionEmail({email});
        history.push('/register/success');
      }
    } catch (e) {
      sendEvent('Contractors/User-Registation-Error', {
        message: e.message,
        email,
      });
      addNotification({
        type: 'error',
        message: ERROR_MSG,
      });
    }
  };

  return (
    <AuthContainer>
      <Text fontSize={32} mb={24} lineHeight="46px">
        Create a free RenoFi account
      </Text>
      <Flex
        flexDirection="column"
        alignItems="center"
        width="100%"
        maxWidth={380}>
        <Label htmlFor="email" css={{fontSize: 16}}>
          Work email address
        </Label>
        <Box width="100%">
          <EmailField
            id="email"
            value={email}
            invalidEmail={invalidEmail}
            onChange={(email) => {
              setEmail(email);
              setInvalidEmail(false);
            }}
          />
        </Box>
        <Box
          width="100%"
          mt={16}
          padding={16}
          css={{border: '1px solid #D8D8D8', borderRadius: 4}}>
          <PrivacyCheckbox
            data-testid="tos-checkbox"
            value={termsAccepted}
            onChange={setTermsAccepted}>
            <CheckboxLabel fontSize={16} lineHeight="20px">
              I agree to use{' '}
              <Link
                css={{textDecoration: 'none'}}
                target="_blank"
                href="https://www.renofi.com/notices/electronic-records-and-signatures/">
                electronic records and <br /> signatures
              </Link>
              , and I agree to RenoFi for <br /> Contractors{' '}
              <Link
                css={{textDecoration: 'none'}}
                target="_blank"
                href="https://www.renofi.com/notices/legal">
                Terms of Service.
              </Link>
            </CheckboxLabel>
          </PrivacyCheckbox>
        </Box>
        <Button
          disabled={isDisabled}
          css={{marginTop: 24, width: '100%'}}
          loading={loading}
          onClick={onSubmit}>
          Sign up for free
        </Button>
        <Box mt={40}>
          Already have an account? <Link href="/login">Sign in</Link>
        </Box>
      </Flex>
    </AuthContainer>
  );
};

export default Signup;
