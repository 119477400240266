import React from 'react';

import {Box} from 'rebass';

import {sendEvent} from '@renofi/analytics';

import {ReactComponent as BoltIcon} from './img/bolt.svg';
import {ReactComponent as ClientsIcon} from './img/clients.svg';
import {ReactComponent as CalculatorIcon} from './img/calculator.svg';
import {ReactComponent as QrCodeScannerIcon} from './img/qr_code_scanner.svg';
import {
  NavbarDesktopLink,
  DesktopWrapper,
  Navigation,
  DesktopSubLine,
} from './styled';
import ScheduleCall from './components/ScheduleCall';

const NavbarDesktop = () => {
  const showScheduleCall = false;
  // @TODO - Add logic determining when / if we show this.

  const isActive = (path) => (match) => match?.url?.includes(path);

  return (
    <DesktopWrapper>
      <Box>
        {showScheduleCall ? <ScheduleCall /> : null}

        <Navigation>
          <NavbarDesktopLink
            isActive={isActive('/welcome')}
            onClick={() => sendEvent('Contractors/QuickStart-Clicked')}
            title="Getting started with RenoFi"
            to={'/welcome'}>
            <BoltIcon />
            <Box>
              Quick start
              <DesktopSubLine>Getting started with RenoFi</DesktopSubLine>
            </Box>
          </NavbarDesktopLink>

          <NavbarDesktopLink
            isActive={isActive('/financing-estimates')}
            onClick={() => sendEvent('Contractors/Financing-Estimates-Clicked')}
            title="Create & share financing quotes"
            to={'/financing-estimates'}>
            <CalculatorIcon />
            <Box>
              Project financing
              <DesktopSubLine>Create & share financing quotes</DesktopSubLine>
            </Box>
          </NavbarDesktopLink>

          <NavbarDesktopLink
            isActive={isActive('/financing')}
            onClick={() => sendEvent('Contractors/Financing-Clicked')}
            to={'/financing'}>
            <ClientsIcon />
            <Box>
              Clients
              <DesktopSubLine>Your RenoFi clients</DesktopSubLine>
            </Box>
          </NavbarDesktopLink>

          <NavbarDesktopLink
            isActive={isActive('/marketing')}
            onClick={() => sendEvent('Contractors/Marketing-Clicked')}
            title="Flyers & stuff"
            to={'/marketing'}>
            <QrCodeScannerIcon />
            <Box>
              Marketing assets
              <DesktopSubLine>
                Co-branded landing page and flyers
              </DesktopSubLine>
            </Box>
          </NavbarDesktopLink>

          <NavbarDesktopLink
            isActive={isActive('/calculator')}
            onClick={() => sendEvent('Contractors/Marketing-Clicked')}
            title="Renovation calculator"
            to={'/calculator'}>
            <CalculatorIcon />
            <Box>
              Renovation Calculator
              <DesktopSubLine>Explore financing options</DesktopSubLine>
            </Box>
          </NavbarDesktopLink>
        </Navigation>
      </Box>
    </DesktopWrapper>
  );
};

export default NavbarDesktop;
