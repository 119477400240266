import React, {useState} from 'react';

import {Box, Flex, Text} from 'rebass';

import {useValidateEmail, useRequestPasswordReset} from '@renofi/api';
import {Alert, Button, Form, Modal} from '@renofi/components';

import {EmailField} from '../../components';

const ForgotPasswordModal = ({onClose}) => {
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const {loading: loadingPasswordReset, requestPasswordReset} =
    useRequestPasswordReset();
  const {loading: loadingValidateEmail, validateEmail} = useValidateEmail();
  const disabled = !email || invalidEmail;
  const loading = loadingPasswordReset || loadingValidateEmail;
  const onSubmit = async () => {
    const {data: validationData} = await validateEmail({variables: {email}});

    if (!validationData?.validateEmail?.valid) {
      setInvalidEmail(true);
      return;
    }

    const rsp = await requestPasswordReset({variables: {email}});
    setSuccess(Boolean(rsp?.data?.requestPasswordReset?.success));
  };

  return (
    <Modal onClose={onClose} show>
      <Text as="strong" fontSize={20}>
        Requesting a new password?
      </Text>

      <Form id="auth-reset-password" onSubmit={onSubmit}>
        <Flex mt={3} flexDirection="column" css={{gap: 16}}>
          <Text>
            Enter your email address below and we’ll send you a link to set it.
          </Text>
          <EmailField
            disabled={loading}
            invalidEmail={invalidEmail}
            onChange={(email) => {
              setEmail(email);
              setInvalidEmail(false);
            }}
            id="forgot_password_email"
            wrapperCss={{width: '100%'}}
            value={email}
          />
          <Button
            loading={loading}
            width={['100%', '50%']}
            disabled={disabled || loading}
            role="submit_reset_password">
            Reset password
          </Button>
        </Flex>
      </Form>

      {success ? (
        <Box mt={3}>
          <Alert info>
            We sent an email to <strong>{email}</strong> with instructions!
          </Alert>
        </Box>
      ) : null}
    </Modal>
  );
};

export default ForgotPasswordModal;
