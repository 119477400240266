import {useMutation} from '@apollo/client';

import {CONFIRM_REFERENCE_DETAILS} from '../mutations';

export default function useConfirmReferenceDetails() {
  const [confirmReferenceDetails, {data, loading, error}] = useMutation(
    CONFIRM_REFERENCE_DETAILS,
  );
  const response = data?.confirmReferenceDetails;
  return {confirmReferenceDetails, response, loading, error};
}
