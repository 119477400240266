import {useState, useContext} from 'react';

import {useHistory} from 'react-router-dom';
import {Flex, Box, Text, Link} from 'rebass';

import {useValidateEmail, useRequestUserLogin} from '@renofi/api';
import {sendEvent} from '@renofi/analytics';
import {lightGray} from '@renofi/utils/src/colors';
import {useSessionStorage} from '@renofi/utils';
import {Alert, Label, Button, Loader} from '@renofi/components';

import AppContext from '../context';
import {AUTH_EMAIL_SESSION_KEY} from '../constants';
import {EmailField, AuthContainer} from '../components';

const Login = () => {
  const history = useHistory();
  const {loading: isRequesting, requestUserLogin} = useRequestUserLogin();
  const {loading: loadingValidateEmail, validateEmail} = useValidateEmail();
  const {loading} = useContext(AppContext);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [email, setEmail] = useState('');

  const [_, setSessionEmail] = useSessionStorage(AUTH_EMAIL_SESSION_KEY);

  const isDisabled =
    !email || isRequesting || loadingValidateEmail || invalidEmail;

  const onSubmit = async () => {
    const {data: validationData} = await validateEmail({variables: {email}});

    if (!validationData?.validateEmail?.valid) {
      setInvalidEmail(true);
      return;
    }
    setSessionEmail({email});
    requestUserLogin({variables: {email}});
    sendEvent('Contractor/Sign-In-Request-Submitted', {email});
    history.push('/login/success');
  };

  return loading ? (
    <Flex p={3} alignItems="center" justifyContent="center">
      <Loader color={lightGray} />
    </Flex>
  ) : (
    <AuthContainer>
      <Text alignSelf="start" fontSize={32} mb={24} lineHeight="46px">
        Sign In
      </Text>
      <Flex flexDirection="column" maxWidth={380} justifyContent="center">
        <Alert info css={{fontSize: 16, lineHeight: '20px', textAlign: 'left'}}>
          Enter your email address below to request a sign in link
        </Alert>
        <Box width="100%" mt={24}>
          <Label htmlFor="email" css={{fontSize: 16}}>
            Email address
          </Label>
          <EmailField
            id="email"
            value={email}
            invalidEmail={invalidEmail}
            onChange={(email) => {
              setEmail(email);
              setInvalidEmail(false);
            }}
          />
        </Box>
        <Button
          disabled={isDisabled}
          css={{width: '100%', marginTop: 24}}
          onClick={onSubmit}>
          Request sign in link
        </Button>
        <Box mt={40} textAlign="center">
          Don't have an account? <Link href="/register">Create one here</Link>
        </Box>
      </Flex>
    </AuthContainer>
  );
};

export default Login;
