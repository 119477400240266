import {useState} from 'react';

import {useApolloClient} from '@apollo/client';
import {Flex} from 'rebass';
import {datadogRum} from '@datadog/browser-rum';

import {sendEvent} from '@renofi/analytics';
import {Button, useNotifications} from '@renofi/components';
import {
  useHomeValuationEstimate,
  useReferClientForFinancing,
  useProductMatchesForEstimate,
  GET_CONTRACTOR_ESTIMATE_BY_ID,
  GET_CONTRACTOR_ESTIMATES,
} from '@renofi/api';

import {ESTIMATES_PER_PAGE} from '../../constants';
import {Layout} from '../../../components';

import AddressSection from './AddressSection';
import ProjectValuesSection from './ProjectValuesSection';
import ProductMatch from './ProductMatch';

const SubmissionForm = ({existingEstimate, onSubmit}) => {
  const addNotification = useNotifications();
  const client = useApolloClient();

  const [propertyAddress, setPropertyAddress] = useState(
    existingEstimate?.propertyAddress,
  );
  const [propertyZipCode, setPropertyZipCode] = useState(
    existingEstimate?.propertyZipCode,
  );
  const [propertyState, setPropertyState] = useState(
    existingEstimate?.propertyState,
  );
  const [renovationCost, setRenovationCost] = useState(
    existingEstimate?.renovationCost,
  );
  const [estimatedCurrentPropertyValue, setEstimatedCurrentPropertyValue] =
    useState(existingEstimate?.estimatedCurrentPropertyValue);
  const [homeValueAvailable, setHomeValueAvailable] = useState(false);

  const {referClientForFinancing, loading: referringClient} =
    useReferClientForFinancing({
      refetchQueries: [
        {
          query: GET_CONTRACTOR_ESTIMATES,
          variables: {limit: ESTIMATES_PER_PAGE},
        },
      ],
    });
  const {
    productMatches,
    fetch: fetchProductMatches,
    loading: fetchingProductMatches,
  } = useProductMatchesForEstimate({lazy: true});

  const estimateDetails = {
    propertyAddress,
    propertyZipCode,
    renovationCost,
    estimatedCurrentPropertyValue,
  };
  const updated = Object.keys(estimateDetails).some(
    (key) => existingEstimate?.[key] !== estimateDetails[key],
  );

  const formCompleted = Boolean(propertyAddress && renovationCost);

  const disabled = !(
    formCompleted &&
    updated &&
    !referringClient &&
    !fetchingProductMatches &&
    productMatches.length > 0
  );

  const fetchHomeValue = useHomeValuationEstimate();
  const getHomeValuation = async (address, stateCode, zipCode) => {
    try {
      address = `${address}, ${stateCode}, ${zipCode}`;
      const rsp = await fetchHomeValue({address});
      const {valueDollars} = rsp?.data?.homeValuationEstimate || {};
      if (valueDollars) {
        setEstimatedCurrentPropertyValue(valueDollars);
        setHomeValueAvailable(true);
      }
      return valueDollars;
    } catch (e) {
      datadogRum.addError('Plunk response error', address);
    }
  };

  function onSearchValueChange(value) {
    if (!value) {
      setEstimatedCurrentPropertyValue(null);
      setPropertyAddress(null);
      setPropertyZipCode(null);
      return;
    }

    setPropertyAddress(value);
  }

  const onAddressSelect = (newLocation) => {
    const address = [newLocation?.streetAddress, newLocation?.city]
      .filter(Boolean)
      .join(', ');
    getHomeValuation(address, newLocation.state, newLocation.zipCode);
    setPropertyAddress(address);
    setPropertyState(newLocation.stateCode);
    setPropertyZipCode(newLocation.zipCode);
  };

  const handleSubmit = async () => {
    try {
      const response = await referClientForFinancing({
        variables: {
          client: {
            id: existingEstimate?.id,
            propertyAddress,
            propertyZipCode,
            propertyState,
            renovationCost,
            estimatedCurrentPropertyValue,
          },
        },
      });

      const resultEstimate =
        response?.data?.referClientForFinancing?.contractorEstimate;

      if (resultEstimate) {
        client.writeQuery({
          query: GET_CONTRACTOR_ESTIMATE_BY_ID,
          variables: {id: referClientForFinancing.id},
          data: {
            contractorEstimateById: resultEstimate,
          },
        });

        const eventSuffix = existingEstimate ? 'Updated' : 'Submitted';
        sendEvent(`Contractors/Financing-Estimate-${eventSuffix}`, {
          ...resultEstimate,
        });
        onSubmit(resultEstimate);
      }
    } catch (e) {
      sendEvent('Contractors/Submitting-Financing-Estimate-Failed', {
        message: e.message,
      });
      addNotification({
        type: 'error',
        message: 'There was a problem submitting the estimate',
      });
    }
  };

  return (
    <>
      <Flex width="100%" flexDirection={['column', 'row']}>
        <AddressSection
          propertyAddress={propertyAddress}
          onSearchValueChange={onSearchValueChange}
          onAddressSelect={onAddressSelect}
        />
        <ProjectValuesSection
          renovationCost={renovationCost}
          setRenovationCost={setRenovationCost}
          propertyAddress={propertyAddress}
          estimatedCurrentPropertyValue={estimatedCurrentPropertyValue}
          setEstimatedCurrentPropertyValue={setEstimatedCurrentPropertyValue}
          homeValueAvailable={homeValueAvailable}
        />
      </Flex>
      <ProductMatch
        estimateDetails={{
          propertyZipCode,
          renovationCost,
          estimatedCurrentPropertyValue,
        }}
        fetchProductMatches={fetchProductMatches}
        fetchingProductMatches={fetchingProductMatches}
        productMatch={productMatches?.[0]}
      />
      <Layout.PanelCTAWrapper>
        <Button
          small
          disabled={disabled}
          loading={referringClient}
          onClick={handleSubmit}>
          {!existingEstimate && 'Save & Continue'}
          {existingEstimate && 'Update & Continue'}
        </Button>
      </Layout.PanelCTAWrapper>
    </>
  );
};

export default SubmissionForm;
